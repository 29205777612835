import React, { useState, useEffect } from "react";
import Time from "./AudioMusicPlayer/audio-player/Time";
import Progress from "./AudioMusicPlayer/audio-player/Progress";

import YouTube from "react-youtube";

const VideoPlayback3 = ({ videoId, playNextTrack, videoToPlaylist }) => {
  const [vidObj, setVidObj] = useState({ init: 0 });
  const [term, setTerm] = useState("");
  const [length, setLength] = useState(0);
  const [time, setTime] = useState(0);
  const [slider, setSlider] = useState(1);
  const [drag, setDrag] = useState(0);
  const [volume, setVolume] = useState(0.8);
  const [newTrack, setNewTrack] = useState(videoId);
  const [TI, setTI] = useState(null);
  let [end, setEnd] = useState(0);
  const [resetPlayer, setResetPlayer] = useState(false);

  const fmtMSS = (s) => new Date(1000 * s).toISOString().substr(15, 4);

  useEffect(() => {
    // setTimeout(() => {
    if (vidObj.init !== 0) {
      console.log("main useEffect triggered ", vidObj);
      const audio = vidObj; //new Audio(trackList[curTrack].url);
      //   const setAudioData = () => {
      setLength(audio.getDuration());
      // setTime(audio.getCurrentTime());
      //   };

      //   const setAudioTime = () => {
      const curTime = audio.getCurrentTime();
      setTime(curTime);
      setSlider(
        curTime ? ((curTime * 100) / audio.getDuration()).toFixed(1) : 0
      );
      //   };

      const setAudioVolume = () => setVolume(audio.getVolume());
      const setAudioEnd = () => setEnd((end += 1));

      // events on audio object
      //   audio.addEventListener("loadeddata", setAudioData);
      //   audio.addEventListener("timeupdate", setAudioTime);
      //   audio.addEventListener("volumechange", setAudioVolume);
      //   audio.addEventListener("ended", setAudioEnd);

      //   setAudio(audio);
      //   setCoverImage(trackList[curTrack].coverImage);
      //   setTitle(trackList[curTrack].title);
      //   setArtist(trackList[curTrack].artist);
      //   setReleasePath(trackList[curTrack].releasePath);
      //   const pl = []
      //   const jb = jukebox.map((item, index) => {
      //     console.log('index', index)
      //     pl.push(index)
      //     return {
      //       url:
      //         item.url ||
      //         "https://firebasestorage.googleapis.com/v0/b/chatbout001.appspot.com/o/users%2FL8s7DzpsAvabSIyNx0xxeuhuryM2%2Ffiles%2FEbu%20Blackitude%20-%20Something%20Black.mp3?alt=media&token=5926ab41-e9ee-49f6-9a4b-048415d224b5",
      //       title: item.track,
      //       artist: item.artist,
      //       releasePath: "XXXX",
      //       tags: item.genre,
      //       coverImage: item.coverart,
      //       userplaylist: false,
      //     };
      //   });

      //   setPlaylist(pl);
      //   console.log('***** playlist', pl)
      //   trackList.push(...jb);

      //return () => {
      //audio.pauseVideo();
      //};
    }
    // }, "1000");
  }, []);

  useEffect(() => {
    console.log("ended");
    // if (isInitialMount.current) {
    //   isInitialMount.current = false;
    // } else {
    //   if (shuffled) {
    //     playlist = shufflePlaylist(playlist);
    //   }
    //   !looped && autoPlayNextTrack ? next() : play();
    // }
  }, [end]);

  useEffect(() => {
    console.log("slider", slider);
  }, [slider]);

  const play = () => {
    vidObj.playVideo();
  };

  const pause = () => {
    vidObj.pauseVideo();
  };

//   useEffect(() => {
//     console.log("video", videoId, vidObj);
//     setResetPlayer(true);
//     if (videoId && vidObj.init !== 0) {
//       //pause();
//     }
//     setTimeout(() => {
//       setResetPlayer(false);
//     }, 300);
//   }, [videoId, vidObj]);


  useEffect(() => {
    console.log("video", videoId);
    setResetPlayer(true);
    setVidObj({init:0});
    setTimeout(() => {
      setResetPlayer(false);
    }, 300);
  }, [videoId]);

  if (!videoId) {
    return <div>Loading Videos...</div>;
  }

  const resetView = () => {
    // If a Youtube player is active, make sure we stop it.
    if (!vidObj) {
      console.log("Player could not be found.");
    } else {
      vidObj.stopVideo();
      vidObj.destroy();
      //vidObj = null;  // Clear out the reference to the destroyed player
      setVidObj(null)
    }
}


  const opts = {
    height: "30",
    width: "30",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  let timeInterval = null;

  const onReady = (event) => {
    // access to player in all event handlers via event.target
    console.log("event.target", event.target);
    const curTime = event.target.getCurrentTime();
    setTime(curTime);
    setSlider(
      curTime ? ((curTime * 100) / event.target.getDuration()).toFixed(1) : 0
    );

    clearInterval(timeInterval);
    timeInterval = setInterval(function () {
      // setCurntTime( txtBox, event.target.getCurrentTime() )
      console.log(
        "event.target.getCurrentTime()",
        event.target.getCurrentTime()
      );
      //   setVidObj(event.target);
      //   console.log("setVidObj...");
      setLength(event.target.getDuration());
      // setTime(audio.getCurrentTime());
      //   };

      //   const setAudioTime = () => {
      const curTime = event.target.getCurrentTime();
      setTime(curTime);
      setSlider(
        curTime ? ((curTime * 100) / event.target.getDuration()).toFixed(1) : 0
      );

      //   audio.addEventListener("loadeddata", setAudioData);
      // event.target.addEventListener("timeupdate", setAudioTime);
      //   audio.addEventListener("volumechange", setAudioVolume);
      //   audio.addEventListener("ended", setAudioEnd);

      //   };
    }, 500);
    // setTI(timeInterval)
    // event.target.pauseVideo();
  };

  const onStateChange = (event) => {
    console.log("onStateChange", event.target);
    console.log(
      "onStateChange event.target.getCurrentTime()",
      event.target.getCurrentTime()
    );
    if (event.data === 0) {
      console.log("track ended");
      playNextTrack();
    }
    setVidObj(event.target);
  };

  const seek = (slider) => {
    console.log("slider", slider);
    const newTime = vidObj.getDuration() * (slider / 100);
    setSlider(newTime);
    vidObj.seekTo(newTime);
  };

  // const videoSrc = `https://www.youtube.com/embed/${video.id.videoId}`;

  //   const handleSubmitToPlaylist = () => {
  //     // console.log("video", video);
  //     videoToPlaylist(video);
  //   };

  return (
    <div>
      <div 
      className="video-player flex rounded-full object-cover mr-3 ml-3 items-center justify-center"
      style={{ width: "50px", height: "50px", backgroundColor: "#000000" }}
      >
        {/* <iframe title='video player' src={videoSrc} /> */}
        {!resetPlayer && (
          <YouTube
          className="video-player align-center"
          style={{ width: "50px", height: "50px" }}
            videoId={videoId}
            opts={opts}
            onReady={onReady}
            onStateChange={onStateChange}
          />
        )}
      </div>
      <div className="vidoe-controls">
        <button
          className="block text-center flex justify-end px-4 py-2 text-white bg-red-600 hover:bg-gray-600 dark:hover:bg-gray-600 dark:hover:text-white"
          onClick={play}
        >
          play
        </button>
        <button
          className="block text-center flex justify-end px-4 py-2 text-white bg-red-600 hover:bg-gray-600 dark:hover:bg-gray-600 dark:hover:text-white"
          onClick={pause}
        >
          pause
        </button>

        <div className="block text-center flex justify-end px-4 py-2 text-white bg-red-600 hover:bg-gray-600 dark:hover:bg-gray-600 dark:hover:text-white">{`${
          !time ? "0:00" : fmtMSS(time)
        }`}</div>
        <Progress
          className="block text-center flex justify-end px-4 py-2 text-white bg-red-600 hover:bg-gray-600 dark:hover:bg-gray-600 dark:hover:text-white"
          value={slider}
          onChange={(e) => {
            setSlider(e.target.value);
            setDrag(e.target.value);
          }}
          onMouseUp={(e) => {
            setSlider(e.target.value);
            setDrag(e.target.value);
            seek(e.target.value);
          }}
          onTouchEnd={(e) => {
            setSlider(e.target.value);
            setDrag(e.target.value);
            seek(e.target.value);
          }}
        />
        <div className="block text-center flex justify-end px-4 py-2 text-white bg-red-600 hover:bg-gray-600 dark:hover:bg-gray-600 dark:hover:text-white">{`${
          !length ? "0:00" : fmtMSS(length)
        }`}</div>
        {/* <Volume
          value={volume}
          onChange={(e) => {
            setVolume(e.target.value / 100);
          }}
        /> */}
      </div>
      {/* <div className="video-description">
        <h2 className="header">{video.snippet.title}</h2>
        <p>{video.snippet.description}</p>
      </div> */}
    </div>
  );
};

export default VideoPlayback3;
