import React, { useState, useEffect } from "react";
import { db } from "./firebase-config";
import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  serverTimestamp,
  doc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
// import { motion } from "framer-motion";
// import { usePositionReorder } from "../../lib/use-position-reorder";
// import { useMeasurePosition } from "../../lib/use-measure-position";

import MuuzTubeSearch from "../MuuzTubeSearch";
import AccordionList from "../AccordionList";
import { groupTracksByGenre } from "../../lib/grouptracksByGenre";
import VideoPlayback from "../VideoPlayback";
import VideoPlayback3 from "../VideoPlayback3";
// import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
// import { SortableList } from "../SortableList/SortableList";
// import { createRange } from "../../lib/utilities";

// function getMockItems() {
//   return createRange(50, (index) => ({ id: index + 1 }));
// }

const CurrentPlaylist = ({
  currentPlaylist,
  playlistId,
  updateCurrentPlaylist,
}) => {
  const [searchTrack, setSearchTrack] = useState(null);
  const [showYoutubeSearch, setShowYoutubeSearch] = useState(false);
  const [searchTrackObj, setSearchTrackObj] = useState(null);
  const [acceptedSong, setAcceptedSong] = useState({
    id: "5fD03BaD7INyhJMne2gfLdPAKbQ2",
  });
  const [showTrackForm, setShowTrackForm] = useState(false);
  const [genre, setGenre] = useState(null);
  const [artist, setArtist] = useState(null);
  const [track, setTrack] = useState(null);
  const [url, setUrl] = useState(null);
  const [youTubeId, setYouTubeId] = useState(null);
  const [currentTrack, setCurrentTrack] = useState({});
  const [coverart, setCoverart] = useState(null);
  const [playlistArray, setPlaylistArray] = useState([]);
  const [groupedTracks, setGroupedTracks] = useState([]);
  const [requestedTrack, setRequestedTrack] = useState({});
  const [draggingItem, setDraggingItem] = useState(null);
  const [newItemImage, setNewItemImage] = useState("");
  const [newItemName, setNewItemName] = useState("");
  const [trackId, setTrackId] = useState(null);
  const [showVideoPlayback, setShowVideoPlayback] = useState(false);
  const [videoId, setVideoId] = useState(null);
  const [currentPlayingTrackIndex, setCurrentPlayingTrackIndex] =
    useState(null);

  useEffect(() => {
    console.log("currentPlaylist", currentPlaylist);
    setGroupedTracks(groupTracksByGenre(currentPlaylist));
    setPlaylistArray(currentPlaylist);
  }, [currentPlaylist]);

  const vtp = (video) => {
    console.log("video", video);
    setShowYoutubeSearch(!showYoutubeSearch);
    const VidObj = {
      artist: video.snippet.artist,
      coverart: video.snippet.coverart,
      genre: "",
      track: video.snippet.track,
      url: "",
      youTubeId: video.id.videoId,
    };
    setCurrentTrack(VidObj);
    setTrack(video.snippet.track);
    setArtist(video.snippet.artist);
    setGenre("");
    setUrl("");
    setYouTubeId(video.id.videoId);
    setCoverart(video.snippet.coverart);

    setShowVideoPlayback(false);
    setShowTrackForm(true);
  };

  const handleSearch = async (event) => {
    let value = event.target.value;

    setSearchTrack(value);
  };

  const handleSubmitSearch = async (event) => {
    event.preventDefault();
    const acceptedSong = {
      request: {
        artist: searchTrack,
        track: "",
      },
    };
    setSearchTrackObj(acceptedSong);
    setShowYoutubeSearch(!showYoutubeSearch);
    // setSearchTrack("");

    // closeAllTabs();
    // create new playlist in firehost
    // record the playist id in the users playlist profile array

    // playlists will still be able to be broken down in the genre lists like the nlm jukebox lists

    // const docRef = collection(db, "playlists");
    // const newpl = await addDoc(docRef, {
    //   playlistName: playlistName,
    //   createdAt: serverTimestamp(),
    //   user: auth.currentUser.displayName,
    //   uid: auth.currentUser.uid,
    // });
    // console.log("newpl", newpl);

    // await setDoc(
    //   doc(db, "users", auth.currentUser.uid, "playlists", newpl.id),
    //   {
    //     id: newpl.id,
    //     playlistName: playlistName,
    //     createdAt: serverTimestamp(),
    //   }
    // );

    // clear the input field and add the new playlist name to the drop down
  };

  const handleSubmitToPlaylist = async () => {
    const VidObj = {
      artist: artist,
      coverart: coverart,
      genre: genre,
      track: track,
      url: url,
      youTubeId: youTubeId,
    };

    if (trackId) {
      // update existing track
      await updateDoc(doc(db, "tracks", trackId), VidObj);

      // initialise track form

      setTrack(null);
      setArtist(null);
      setGenre(null);
      setUrl(null);
      setYouTubeId(null);
      setCoverart(null);
      setSearchTrack("");
      setShowTrackForm(false);
      updateCurrentPlaylist(playlistId);
      setCurrentTrack(VidObj);
    } else {
      setPlaylistArray([]);
      // add new track to playlist
      // search for this track youtubeId in tracks collection if not there create
      const docTrackRef = collection(db, "tracks");
      const q = query(docTrackRef, where("youTubeId", "==", youTubeId));
      let id = null;
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        console.log("doc.id", doc.id);
        id = doc.id;
      });

      // update currentplaylist with this new track, no duplicates
      new Promise(async (resolve, reject) => {
        if (id === null) {
          const docAddTrackRef = collection(db, "tracks");
          const newTrack = await addDoc(docAddTrackRef, VidObj);
          id = newTrack.id;
        }
        if (id !== null) resolve(id);
        else reject();
      }).then(async (id) => {
        await setDoc(doc(db, "playlists", playlistId.id, "tracks", id), {
          createdAt: serverTimestamp(),
          id: id,
          ordinal: 0,
        });

        // initialise track form
        setCurrentTrack(null);
        setTrack(null);
        setArtist(null);
        setGenre(null);
        setUrl(null);
        setYouTubeId(null);
        setCoverart(null);
        setSearchTrack("");
        setShowTrackForm(false);
      });
    }
  };

  const handleTrackName = (e) => {
    const track = e.target.value;
    setTrack(track);
  };

  const handleArtist = (e) => {
    const artist = e.target.value;
    setArtist(artist);
  };

  const handleGenre = async (event) => {
    const genre = event.target.value;
    setGenre(genre);
  };

  const handleAudioURL = (e) => {
    const url = e.target.value;
    setUrl(url);
  };

  const handleYouTubeId = (e) => {
    const youtubeId = e.target.value;
    setYouTubeId(youtubeId);
  };

  const handleCoverart = (e) => {
    const coverart = e.target.value;
    setCoverart(coverart);
  };

  const setRequestedTrackSetter = (item) => {
    setRequestedTrack(item);
  };
  const pickTrack = async (event, item, i) => {
    console.log("pickTrack", item, i);
  };

  // const items = [60, 80, 120, 40];

  // const [order, updatePosition, updateOrder] =
  //   usePositionReorder(items);

  // const Item = ({ i, height, updatePosition, updateOrder, item }) => {
  //   console.log('height', height)
  //   const [isDragging, setDragging] = useState(false);

  //   const ref = useMeasurePosition((pos) => updatePosition(i, pos));

  //   return (
  //     <li
  //       className="flex items-center py-4 px-3 jukebox-divider"
  //       style={{
  //         padding: 0,
  //         height,
  //         // If we're dragging, we want to set the zIndex of that item to be on top of the other items.
  //         zIndex: isDragging ? 3 : 1,
  //       }}
  //     >
  //       <motion.div
  //         ref={ref}
  //         layout
  //         initial={false}
  //         style={{
  //           background: "white",
  //           height,
  //           borderRadius: 5,
  //         }}
  //         whileHover={{
  //           scale: 1.03,
  //           boxShadow: "0px 3px 3px rgba(0,0,0,0.15)",
  //         }}
  //         whileTap={{
  //           scale: 1.12,
  //           boxShadow: "0px 5px 5px rgba(0,0,0,0.1)",
  //         }}
  //         drag="y"
  //         onDragStart={() => setDragging(true)}
  //         onDragEnd={() => setDragging(false)}
  //         onViewportBoxUpdate={(_viewportBox, delta) => {
  //           isDragging && updateOrder(i, delta.y.translate);
  //         }}
  //       />
  //       {/* <span className="text-gray-100 text-lg font-black font-medium">
  //         {i + 1}.
  //       </span> */}
  //       {/* <img
  //         className=" rounded-full object-cover mr-1"
  //         src={item.coverart}
  //         style={{ width: "48px" }}
  //         alt={item.track}
  //       />
  //       <div className="flex-1" style={{ width: "50%" }}>
  //         <h3 className="text-lg font-medium text-black overflow-hidden">
  //           {item.artist}
  //         </h3>
  //         <p className="text-black text-base">{item.track} </p>
  //         <p className="text-black text-base">{item.genre} </p>
  //       </div> */}
  //     </li>
  //   );
  // }

  const handleDragStart = (e, item) => {
    // this.setState({ draggingItem: item });
    setDraggingItem(item);

    e.dataTransfer.setData("text/plain", "");
  };

  const handleDragEnd = () => {
    setDraggingItem(null);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, targetItem) => {
    // const { draggingItem, items } = this.state;
    if (!draggingItem) return;

    const currentIndex = playlistArray.indexOf(draggingItem);
    const targetIndex = playlistArray.indexOf(targetItem);

    let p = playlistArray;
    if (currentIndex !== -1 && targetIndex !== -1) {
      p.splice(currentIndex, 1);
      p.splice(targetIndex, 0, draggingItem);
      console.log("p", p);
      setPlaylistArray(p);
    }
  };

  const handleNameChange = (e) => {
    // this.setState({ newItemName: e.target.value });
    setNewItemName(e.target.value);
  };

  const handleImageChange = (e) => {
    // this.setState({ newItemImage: e.target.value });
    setNewItemImage(e.target.value);
  };

  const handleSavePlaylist = (e) => {
    e.preventDefault();
    console.log("save this playlist order", playlistArray);
    playlistArray.forEach(async (item, i) => {
      await updateDoc(doc(db, "playlists", playlistId.id, "tracks", item.id), {
        ordinal: i,
      });
    });
    // rerender whole component
    setPlaylistArray([]);
    updateCurrentPlaylist(playlistId);
  };
  //
  const handleEditTrack = (e, track) => {
    e.preventDefault();
    // track.id
    setTrackId(track.id);
    setTrack(track.track);
    setArtist(track.artist);
    setGenre(track.genre);
    setUrl(track.url);
    setYouTubeId(track.youTubeId);
    setCoverart(track.coverart);

    setShowVideoPlayback(false);
    setShowTrackForm(true);
  };

  const handleVideoPlayback = (e, track, i) => {
    e.preventDefault();
    setShowVideoPlayback(false);
    setShowTrackForm(false);
    setVideoId(track.youTubeId);
    setCurrentPlayingTrackIndex(i);
    setTimeout(() => {
      setShowVideoPlayback(true);
    }, 300);
  };

  const playNextTrack = () => {
    setShowVideoPlayback(false);
    const i = currentPlayingTrackIndex;
    let track;
    if (playlistArray.length === i + 1) {
      setCurrentPlayingTrackIndex(0);
      track = playlistArray[0];
    } else {
      setCurrentPlayingTrackIndex(i + 1);
      track = playlistArray[i + 1];
    }
    setVideoId(track.youTubeId);
    setTimeout(() => {
      setShowVideoPlayback(true);
    }, 300);
  };

  // const addNewItem = () => {
  //   // Generate a unique ID for the new item
  //   const newItemId = Math.max(...playlistArray.map((item, i) => i)) + 1;
  //   const newItem = {
  //     id: newItemId,
  //     name: this.state.newItemName,
  //     image: this.state.newItemImage,
  //   };

  //   // Add the new item to the state
  //   this.setState({
  //     items: [...this.state.items, newItem],
  //     newItemName: "", // Clear the input fields
  //     newItemImage: "",
  //   });
  // };

  return (
    <>
      <div className="flex justify-between flex-row flex-grow w-full pt-8">
        <div className="text-black flex-grow w-3/6">
          {showYoutubeSearch && (
            <div
              onClick={() => {
                setShowYoutubeSearch(false);
              }}
              id="notifyMuuzbox-close"
              class="section-close muuzbox-close rounded cursor-pointer"
            ></div>
          )}
          {!showYoutubeSearch && (
            <>
              <div className="overflow-auto currentplaylist">
                <h3>Current Playlist</h3>

                <AccordionList
                  data={groupedTracks}
                  setRequestedTrack={setRequestedTrackSetter}
                  pickTrack={pickTrack}
                  listTitle={"Grouped Tracks by Genre"}
                  mode={"light"}
                />

                <h2 className="text-black uppercase mt-2">Individual Tracks</h2>

                {/* sortable playlist  */}
                <div className="sortable-list">
                  {playlistArray.map((item, i) => (
                    <div
                      key={i}
                      className={`item ${
                        item === draggingItem ? "dragging" : ""
                      }`}
                      draggable="true"
                      onDragStart={(e) => handleDragStart(e, item)}
                      onDragEnd={handleDragEnd}
                      onDragOver={handleDragOver}
                      onDrop={(e) => handleDrop(e, item)}
                    >
                      <div
                        className="flex items-center py-4 px-3 jukebox-divider"
                        key={i}
                        onClick={(event) => {
                          setRequestedTrack(item);
                          pickTrack(event, item, i);
                        }}
                      >
                        <span className="text-black text-lg font-black font-medium">
                          {i + 1}.
                        </span>
                        <img
                          className=" rounded-full object-cover mr-3 ml-3"
                          src={item.coverart}
                          style={{ width: "48px", height: "48px" }}
                          alt={item.track}
                        />
                        <div
                          className="flex-1 text-left"
                          style={{ width: "50%" }}
                        >
                          <h3 className="text-lg font-medium text-black overflow-hidden">
                            {item.artist}
                          </h3>
                          <p className="text-black text-base">{item.track} </p>
                          <p className="text-black text-base">{item.genre} </p>
                        </div>
                        <div>
                          {/* play button */}
                          <button
                            onClick={(event) =>
                              handleVideoPlayback(event, item, i)
                            }
                            className="flex items-center justify-center bg-indigo-500 hover:bg-indigo-600 rounded-md text-white px-4 py-1  w-6 h-6 mt-4 mr-2"
                          >
                            <span className="ml-0">
                              <svg
                                alt={"edit " + item.track}
                                title={"edit " + item.track}
                                className="w-4 h-4 -mt-px text-white"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M21.4086 9.35258C23.5305 10.5065 23.5305 13.4935 21.4086 14.6474L8.59662 21.6145C6.53435 22.736 4 21.2763 4 18.9671L4 5.0329C4 2.72368 6.53435 1.26402 8.59661 2.38548L21.4086 9.35258Z"
                                  fill="#FFFFFF"
                                />
                              </svg>
                            </span>
                          </button>

                          {/* edit button */}
                          <button
                            onClick={(event) => handleEditTrack(event, item)}
                            className="flex items-center justify-center bg-indigo-500 hover:bg-indigo-600 rounded-md text-white px-4 py-1  w-6 h-6 mt-4 mr-2"
                          >
                            <span className="ml-0">
                              <svg
                                alt={"edit " + item.track}
                                title={"edit " + item.track}
                                className="w-4 h-4 -mt-px text-white"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M20.8477 1.87868C19.6761 0.707109 17.7766 0.707105 16.605 1.87868L2.44744 16.0363C2.02864 16.4551 1.74317 16.9885 1.62702 17.5692L1.03995 20.5046C0.760062 21.904 1.9939 23.1379 3.39334 22.858L6.32868 22.2709C6.90945 22.1548 7.44285 21.8693 7.86165 21.4505L22.0192 7.29289C23.1908 6.12132 23.1908 4.22183 22.0192 3.05025L20.8477 1.87868ZM18.0192 3.29289C18.4098 2.90237 19.0429 2.90237 19.4335 3.29289L20.605 4.46447C20.9956 4.85499 20.9956 5.48815 20.605 5.87868L17.9334 8.55027L15.3477 5.96448L18.0192 3.29289ZM13.9334 7.3787L3.86165 17.4505C3.72205 17.5901 3.6269 17.7679 3.58818 17.9615L3.00111 20.8968L5.93645 20.3097C6.13004 20.271 6.30784 20.1759 6.44744 20.0363L16.5192 9.96448L13.9334 7.3787Z"
                                  fill="#FFFFFF"
                                />
                              </svg>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <button
                onClick={(event) => handleSavePlaylist(event)}
                className="flex items-center justify-center bg-indigo-500 hover:bg-indigo-600 rounded-xl text-white px-4 py-1  w-12 h-12 mt-4 mr-2"
              >
                <span className="ml-0">
                  <svg
                    className="w-8 h-8 -mt-px text-white"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4 6C4 4.89543 4.89543 4 6 4H12H14.1716C14.702 4 15.2107 4.21071 15.5858 4.58579L19.4142 8.41421C19.7893 8.78929 20 9.29799 20 9.82843V12V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V6Z"
                      stroke="#ffffff"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8 4H13V7C13 7.55228 12.5523 8 12 8H9C8.44772 8 8 7.55228 8 7V4Z"
                      stroke="#ffffff"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7 15C7 13.8954 7.89543 13 9 13H15C16.1046 13 17 13.8954 17 15V20H7V15Z"
                      stroke="#ffffff"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </button>
            </>
          )}
        </div>
        <div className="text-black flex-grow w-3/6">
          <div className="flex flex-row flex-grow w-full px-4 font-black">
            {showYoutubeSearch && (
              <div className="text-black flex-grow text-right">
                {searchTrack}
              </div>
            )}
            {!showYoutubeSearch && (
              <div className="text-black flex-grow">
                <input
                  id="new-playlist"
                  type="text"
                  placeholder="search track for playlist"
                  value={searchTrack}
                  onChange={handleSearch}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") handleSubmitSearch(e);
                  }}
                  className="flex w-full text-gray-800 dark:text-gray-800 border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-8"
                />
              </div>
            )}
            {!showYoutubeSearch && (
              <div className="ml-2">
                <button
                  onClick={(event) => handleSubmitSearch(event)}
                  className="flex items-center justify-center bg-indigo-500 hover:bg-indigo-600 rounded-xl text-white px-4 py-1  w-12 h-12 mt-4 mr-2"
                >
                  <span className="ml-0">
                    <svg
                      fill="#ffffff"
                      className="mr-1"
                      width="14px"
                      height="14px"
                      viewBox="0 0 16 16"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.027 9.92L16 13.95 14 16l-4.075-3.976A6.465 6.465 0 0 1 6.5 13C2.91 13 0 10.083 0 6.5 0 2.91 2.917 0 6.5 0 10.09 0 13 2.917 13 6.5a6.463 6.463 0 0 1-.973 3.42zM1.997 6.452c0 2.48 2.014 4.5 4.5 4.5 2.48 0 4.5-2.015 4.5-4.5 0-2.48-2.015-4.5-4.5-4.5-2.48 0-4.5 2.014-4.5 4.5z"
                        fill-rule="evenodd"
                      />
                    </svg>
                  </span>
                </button>
              </div>
            )}
          </div>

          {/* video playback */}
          {showVideoPlayback && (
            <div className="h-[300px] pl-4 flex items-center">
              <div className="flex flex-row justify-center items-center">
                <VideoPlayback3
                  videoId={videoId}
                  playNextTrack={playNextTrack}
                />
              </div>
            </div>
          )}

          {/* track details form */}
          {showTrackForm && (
            <div className=" rounded-xl px-8 pt-4 h-96 overflow-auto">
              ADD TRACK: {currentTrack.track}
              <div className="text-black flex-grow mb-2">
                <input
                  id="new-playlist-track"
                  type="text"
                  placeholder="Track Name"
                  value={track}
                  onChange={handleTrackName}
                  className="flex w-full text-gray-800 dark:text-gray-800 border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-4"
                />
              </div>
              <div className="text-black flex-grow mb-2">
                <input
                  id="new-playlist-artist"
                  type="text"
                  placeholder="Artist"
                  value={artist}
                  onChange={handleArtist}
                  className="flex w-full text-gray-800 dark:text-gray-800 border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-4"
                />
              </div>
              <div className="text-black flex-grow mb-2">
                <input
                  id="new-playlist-genre"
                  type="text"
                  placeholder="Genre"
                  value={genre}
                  onChange={handleGenre}
                  className="flex w-full text-gray-800 dark:text-gray-800 border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-4"
                />
              </div>
              <div className="text-black flex-grow mb-2">
                <input
                  id="new-playlist-audio"
                  type="text"
                  placeholder="Audio URL"
                  value={url}
                  onChange={handleAudioURL}
                  className="flex w-full text-gray-800 dark:text-gray-800 border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-4"
                />
              </div>
              <div className="text-black flex-grow mb-2">
                <input
                  id="new-playlist-ytid"
                  type="text"
                  placeholder="YouTube ID"
                  value={youTubeId}
                  onChange={handleYouTubeId}
                  className="flex w-full text-gray-800 dark:text-gray-800 border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-4 disabled"
                />
              </div>
              <div className="text-black flex-grow mb-2">
                <div className="flex flex-row">
                  <input
                    id="new-playlist-coverart"
                    type="text"
                    placeholder="Cover Art"
                    value={coverart}
                    onChange={handleCoverart}
                    className="flex w-full text-gray-800 dark:text-gray-800 border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-4 mr-2"
                  />
                  <img className="w-24" src={coverart} alt="" />
                </div>
              </div>
              <div className="ml-2">
                <button
                  onClick={(event) => handleSubmitToPlaylist(event)}
                  className="flex items-center justify-center bg-indigo-500 hover:bg-indigo-600 rounded-xl text-white px-4 py-1  w-12 h-12 mt-4 mr-2"
                >
                  <span className="ml-0">
                    <svg
                      className="w-8 h-8 -mt-px text-white"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4 6C4 4.89543 4.89543 4 6 4H12H14.1716C14.702 4 15.2107 4.21071 15.5858 4.58579L19.4142 8.41421C19.7893 8.78929 20 9.29799 20 9.82843V12V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V6Z"
                        stroke="#ffffff"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8 4H13V7C13 7.55228 12.5523 8 12 8H9C8.44772 8 8 7.55228 8 7V4Z"
                        stroke="#ffffff"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M7 15C7 13.8954 7.89543 13 9 13H15C16.1046 13 17 13.8954 17 15V20H7V15Z"
                        stroke="#ffffff"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      {showYoutubeSearch && (
        <div className=" rounded-xl px-4 h-96 overflow-auto">
          <MuuzTubeSearch acceptedSong={searchTrackObj} vtp={vtp} />
        </div>
      )}
    </>
  );
};

export default CurrentPlaylist;
